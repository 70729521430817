<template>
  <div class="pdp-accordion-container">
    <v-expansion-panels v-model="active" accordion tile>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="title-semibold text-title"
          hideActions
        >
          <div class="pdp-accordion-title">
            <div>
              <slot name="title"></slot>
            </div>
            <div>
              <v-icon class="pdp-accordion-title-icon"
                >mdi-chevron-down</v-icon
              >
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot name="content"></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'FooterAccordionItem',

  props: {},

  data() {
    return {
      active: false,
    }
  },
}
</script>

<style lang="stylus" scoped>
.pdp-accordion-title{
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    padding: 0;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    vertical-align: top;
    font-weight: 600;
    margin: 0;
    letter-spacing: -.02em;
    color: #121212;

    .pdp-accordion-title-icon{
        font-size 14px
        padding 6px
    }
}
::v-deep {
    .v-expansion-panel::before {
        box-shadow: none;
    }

    .v-expansion-panels .v-expansion-panel::after {
        border: none;
    }

    .v-expansion-panel-header{
        padding: 18px 0;
        min-height: fit-content;

        border-bottom: 0.5px solid #7d7d7d;
    }

    .v-expansion-panel-content .v-expansion-panel-content__wrap{
        padding: 24px 0 8px;
    }

    .v-expansion-panel-header {
        color: rgba(18,18,18,.75);

        .pdp-accordion-title-icon{
            color: rgba(18,18,18,.75);
        }
    }

    .v-item--active {
        .v-expansion-panel-header {
            .pdp-accordion-title{
                color: $color-bnn !important;
            }

            .pdp-accordion-title-icon{
                color: $color-bnn !important;
                transform: rotate(180deg);
            }
        }
    }
}
</style>
